.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  overflow: hidden;
  cursor: zoom-out;
}

.gallery-content {
}

.close-btn {
  position: fixed;
  top: 2%;
  right: 4.5%;
  width: 9%;
  cursor: pointer;
  z-index: 200;
}

.close-btn:hover {
  width: 9.1%;
  transition-duration: 300ms;
}

@media (min-width: 550px) {
  .close-btn {
    width: 7rem;
  }
  .close-btn:hover {
    width: 7.5rem;
    transform: translate(0.25rem, -0.25rem);
    transition-duration: 300ms;
  }
}

.close-btn svg {
  stroke: #fff;
}

.close-btn:hover svg {
  stroke: rgb(71, 122, 180);
  transition-duration: 300ms;
}

.description {
  position: absolute;
  bottom: 1.6rem;
  left: 50%;
  transform: translate(-50%);
  font-weight: 700;
  z-index: 150;
  font-size: 1.6rem;
  text-shadow: 1px 1px 2px black;
  color: white;

  background-color: rgba(0, 0, 0, 0.3);
  padding: 1rem 2rem;
  line-height: 1.25;
  text-align: center;
}

@media (min-width: 800px) {
  .description {
    bottom: 5rem;
    font-size: 3.2rem;
  }
}

.gallery-element {
}

@media (min-width: 550px) {
  .gallery-element {
    transform: scale(0.9);
    padding-top: 4%;
  }
}
