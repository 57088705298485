* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Bellota", cursive;
  font-style: normal;
  line-height: 1.25;
  font-weight: 700;
  color: #1e293b;
  padding: 0 1.2rem 4rem;
  background: linear-gradient(
      rgba(255, 255, 255, 0.95),
      rgba(255, 255, 255, 0.95)
    ),
    url("img/bg.jpg") fixed;
  background-size: 100vw;
}

@media (min-width: 550px) {
  body {
    padding: 4rem 8rem;
  }
}

body:after {
  display: none;
  content: url("img/email_hover.png") url("img/whatsapp_hover.png")
    url("img/telegram_hover.png") url("img/mobile_hover.png");
}

a {
  text-decoration: none;
}

a.anchor {
  display: block;
  position: relative;
  top: 0;
  visibility: hidden;
}

@media (min-width: 550px) {
  a.anchor {
    top: -5rem;
  }
}

a:link,
a:visited {
  color: #0e3b4e;
}

a:hover,
a:active {
  color: #0891b2;
}

.T1 {
  font-size: 2rem;
}

.T2 {
  font-weight: 400;
  font-size: 1.6rem;
}

.H1 {
  font-size: 4rem;
}

.H2 {
  font-size: 3.2rem;
}

.H3 {
  font-size: 2.6rem;
}

main {
  max-width: 128rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding-top: 10.2rem;
}

@media (min-width: 550px) {
  main {
    gap: 6rem;
    padding-top: 12.5rem;
  }
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
}

@media (min-width: 550px) {
  section {
    gap: 4rem;
  }
}

.section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
}

.section-header-text {
  font-size: 3.2rem;
  text-align: center;
}

@media (min-width: 550px) {
  .section-header-text {
    font-size: 4rem;
  }
}

.section-header-img {
  height: 6.3rem;
}

@media (min-width: 550px) {
  .section-header-img {
    height: 7.5rem;
  }
}

/* HERO */
.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.hero-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 60rem;
}

.hero-img {
  width: 100%;
  max-width: 60rem;
}

.hero-text {
  width: 100%;
}

.hero-description {
  color: #64748b;
}

/* PLAN */
.plan-subheader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
}

.plan-subheader-header {
  font-size: 2.6rem;
}

.plan-subheader-description {
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
}

@media (min-width: 550px) {
  .plan-subheader-header {
    font-size: 3.2rem;
  }
  .plan-subheader-description {
    font-size: 2rem;
  }
}

.floor1-img {
  width: 100%;
  max-width: 79.5rem;
}

.floor2-img {
  max-width: 15rem;
}

@media (min-width: 550px) {
  .floor2-img {
    max-width: 37.9rem;
  }
}

/* GALLERY */
.gallery-section-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
  width: 100%;
}

@media (min-width: 550px) {
  .gallery-section-container {
    gap: 4rem;
    padding-inline: 11.6rem;
  }
}

.gallery-subsection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.gallery-subsection-header {
  font-size: 2.6rem;
}

@media (min-width: 550px) {
  .gallery-subsection-header {
    font-size: 3.2rem;
  }
}

/* VIDEO */
/*
.video-container {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}

.video-container iframe,
embed,
object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  z-index: -100;
}

 */
iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
}

/* And set the max-width of the parent element */
.video-wrap {
  width: 100%;
}

/*
@media (min-width: 550px) {
  #video {
    padding-inline: 11.6rem;
  }
}

 */

/* GRID-4 */
.grid-4 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
}

@media (min-width: 550px) {
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }
}

.grid-4-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
}

@media (min-width: 550px) {
  .grid-4-img {
    max-width: 29.6rem;
  }
}

.grid-4-img {
  width: 100%;
}

.diff-text-desktop {
  display: none;
}
.diff-text-mobile {
  display: block;
}

@media (min-width: 550px) {
  .diff-text-desktop {
    display: block;
  }
  .diff-text-mobile {
    display: none;
  }
}

/* CONTACTS */
.socials {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 4rem;
}

.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.3rem;
  cursor: pointer;
}

.telegram {
  background-image: url("img/telegram.png");
  background-size: contain;
  width: 6rem;
  height: 6rem;
}

.social:hover .telegram {
  background-image: url("img/telegram_hover.png");
  transition-duration: 300ms;
}

.whatsapp {
  background-image: url("img/whatsapp.png");
  background-size: contain;
  width: 6rem;
  height: 6rem;
}

.social:hover .whatsapp {
  background-image: url("img/whatsapp_hover.png");
  transition-duration: 300ms;
}

.email {
  background-image: url("img/email.png");
  background-size: contain;
  width: 6rem;
  height: 6rem;
}

.social:hover .email {
  background-image: url("img/email_hover.png");
  transition-duration: 300ms;
}

.social:hover p {
  color: #0891b2;
  transition-duration: 300ms;
}

.social-img {
  width: 6rem;
}

.phone {
  display: flex;
  gap: 2rem;
}

.phone-img {
  background-image: url("img/mobile.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 3.2rem;
}

#contacts:hover .phone-img {
  transition-duration: 300ms;
  background-image: url("img/mobile_hover.png");
}

#contacts:hover p {
  color: #0891b2;
  transition-duration: 300ms;
}
