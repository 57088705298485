.btn-mobile-menu {
  position: fixed;
  top: 1.2rem;
  right: 1.2rem;
  background-color: #0e3b4e;
  width: 5rem;
  height: 5rem;
  border-radius: 8px;
  border: none;
  transition: 300ms;
  z-index: 100;
}

.btn-mobile-menu:hover {
  background-color: #0891b2;
  cursor: pointer;
}

@media (min-width: 550px) {
  .btn-mobile-menu {
    display: none;
  }
}

.backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
}

@media (min-width: 550px) {
  .backdrop {
    display: none !important;
  }
}

.menu-container {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem 1.2rem;
  border: 2px solid #0e3b4e;
  gap: 2rem;
  position: fixed;
  top: 7.4rem;
  left: 1.2rem;
  right: 1.2rem;
}

@media (min-width: 550px) {
  .menu-container {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2rem 4rem;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    top: 4rem;
    margin-inline: auto;
    left: 0;
    right: 0;
    max-width: 128rem;
  }
}

.hidden {
  display: none;
}
