.gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, auto);
  grid-auto-columns: auto;
  grid-column-gap: 2rem;
  grid-row-gap: 20px;
  width: 100%;
  justify-content: center;
}

@media (min-width: 550px) {
  .gallery-wrapper {
    grid-template-columns: repeat(auto-fill, 29.6rem);
  }
}
