.gallery-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  cursor: zoom-in;
}

@media (min-width: 550px) {
  .gallery-image-container {
    max-width: 29.6rem;
  }
}

.gallery-img {
  width: 100%;
}

.text-center {
  text-align: center;
}
